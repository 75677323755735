// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: '',
  apiKey: '213f9223288c220bdb69aebb5d342d7723b3f2eb2e24b0cbfb4227a474daad2d4f15f2feb55abda4055e43cbfff5923edf740fb640d9896a9d5ba24786081fc9',
  previewKey: 'f6f1b9fd0aa3054e6a3c0ef8b1851fc6'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
